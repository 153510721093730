
import Vue from "vue";

import http from "@/http";

import { SMS } from "@/interfaces";
import dayjs from "dayjs";

import { mdiMagnify } from "@mdi/js";

export interface Query {
  to: string;
}
export interface DataType {
  messages: SMS.Message[];
  query: Query;
}

export interface FormattedMessage {
  id: string;
  body: string;
  to: string;
  status: string;
  sent: string;
}

export default Vue.extend({
  data(): DataType {
    return {
      messages: [],
      query: {
        to: ""
      }
    };
  },
  computed: {
    icon() {
      return {
        mdiMagnify
      };
    },
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "SMS",
          disabled: true,
          to: "/messages"
        }
      ];
    },
    formatted(): FormattedMessage[] {
      if (this.messages.length === 0) {
        return [];
      }

      return this.messages.map(message => {
        const body = message.body.substring(0, 40);
        const to = message.to.replace(/\+81/, "0");

        const status = message.status === "delivered" ? "送信" : "未送信";

        const sent = dayjs(message.dateSent.date)
          .add(9, "h")
          .format("YYYY-MM-DD HH:mm");

        return {
          id: message.sid,
          body,
          to,
          status,
          sent
        };
      });
    },
    disabled(): boolean {
      if (this.query.to.length === 0) {
        return false;
      }
      return /^0\d{10}$/g.test(this.query.to) === false;
    }
  },
  watch: {
    async $route() {
      await this.fetchData();
    }
  },
  async mounted() {
    const { to } = this.$route.query;

    if (to) {
      this.query.to = to.toString();
    }

    await this.fetchData();
  },
  methods: {
    async submit() {
      await this.$router.replace({
        path: "/messages",
        query: {
          to: this.query.to
        }
      });
    },
    async fetchData() {
      const { to } = this.query;

      const params = {
        to
      };

      const { data } = await http.get<SMS.Message[]>("messages", {
        params
      });

      this.messages = data;
    }
  }
});
